.progress-bar {
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 0.9rem;

  &__filled {
    height: 100%;
    width: 0;
    background-color: #f98140;
    border-radius: inherit;
  }
}
