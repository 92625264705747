.basics__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .image__wrapper {
    width: 11.5rem;
    height: 11.5rem;
    margin-top: 2rem;
    border: 0.1rem dotted black;
  }

  .block__input-info {
    .duotext,
    .selectInput {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 10rem;
    }

    .duration {
      display: flex;
      // flex-direction: column;
    }

    .input.input {
      outline: none;
      padding: 1rem;
      width: 100%;
    }

    .textarea {
      resize: vertical;
      outline: none;
      width: 100%;
      padding: 1rem;
      min-height: 5rem;
    }

    .button {
      width: 20rem;
      height: 4rem;
      margin-left: 10rem;
    }

    .submit {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .submit-button {
      width: 18rem;
      height: 4rem;
      margin-top: 1rem;
      border-radius: 3rem;
      border: none;
      cursor: pointer;
      background-color: rgb(182, 182, 182);
    }

    .description {
      text-align: center;
      width: 37rem;
      align-self: center;
      font-size: 1.5rem;
      margin-top: 1.5rem;
      line-height: 1.8rem;
      color: rgb(88, 88, 88);
    }
  }
}
