.counter__wrapper,
.counter__wrapper .icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.counter__wrapper .icon {
  cursor: pointer;
}

.counter__wrapper .counter {
  padding-left: 10px;
}
