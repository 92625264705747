@import '../../../../../../config/constants.scss';

.productDetails__category {
  margin-top: 30px;

  .title,
  .choose-category {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    color: $color-dark-gray;
  }

  .title {
    width: 100%;
    height: 24px;

    font-size: 16px;
    line-height: 126.2%;
  }

  .buttons {
    margin-top: 10px;
  }

  .tags {
    margin-top: 10px;
  }
}
