@import '../../../../../config/constants.scss';

.footer {
  &__arrow-next,
  &__arrow-back {
    display: flex;
    // justify-content: space-between;
    // width: 82px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    color: $color-dark-gray;

    .icon {
      width: 12px;
      height: 21px;
    }
  }

  &__arrow-back {
    .text {
      margin-left: 12px;
    }
  }
  &__arrow-next {
    display: flex;
    justify-content: flex-end;

    .text {
      margin-right: 12px;
    }
  }
}
