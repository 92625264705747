@import '../../../../../../config/constants.scss';

.commission__rate {
  display: flex;
  flex-direction: column;

  .title {
    height: 31px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 119.2%;

    color: $color-black;
  }

  .description {
    margin-top: 10px;
    height: 112px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 157.2%;

    color: $color-dark-gray;
  }

  .input-number {
    margin-top: 23px;
  }
}
