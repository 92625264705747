@import '../../../../config/constants.scss';

.campaignRegistration {
  &__inputNumber {
    display: flex;
    width: 100%;
    height: 46px;

    input {
      width: 106px;
      height: 46px;
      padding: 8px;
      border: 1px solid #d5d5d5;
      border-radius: 7px;
      outline: none;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 125%;
      text-align: right;

      color: $color-dark-gray;

      &:focus {
        border: 1px solid #ff6d0a;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: 'textfield';
    }

    .measure {
      display: flex;
      align-items: center;
      margin-left: 12px;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      line-height: 125%;

      color: $color-dark-gray;
    }

    .measure.not-active {
      color: $color-light-gray-2;
    }
  }

  &__inputTextarea {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 140px;

    .title,
    textarea {
      width: 100%;

      font-family: 'Poppins';
      font-style: normal;
      color: $color-dark-gray;
    }

    .title {
      height: 24px;

      font-weight: 600;
      font-size: 16px;
      line-height: 126.2%;
    }

    textarea {
      height: 106px;
      padding: 8px;
      border-radius: 8px;
      border: 1px solid $color-light-gray-2;
      resize: none;
      outline: none;

      font-weight: normal;
      font-size: 14px;
      line-height: 125%;

      &:focus {
        border: 1px solid $color-orange-1;
      }
    }
  }
}

.campaignRegistration__inputNumber .measure.price,
.campaignRegistration__inputNumber .measure.commission {
  font-size: 32px !important;
}

.campaignRegistration__inputNumber .measure.duration,
.campaignRegistration__inputNumber .measure.goal {
  font-size: 18px !important;
}
