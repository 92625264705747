.signup {
  position: relative;
  width: 100%;
  max-width: 480px;
  background-color: #f7f7fa;

  .logo {
    display: flex;
    padding-top: 40px;
    justify-content: center;
  }
}
