@import '../../../../../../config/constants.scss';

.productDetails__productImages {
  display: flex;
  flex-direction: column;
  width: 100%;

  .title {
    height: 24px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 126.2%;

    color: $color-dark-gray;
  }

  .images {
    display: flex;
    flex-wrap: wrap;
  }

  .description {
    margin-top: 10px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 157.2%;

    color: $color-middle-gray;
  }
}
