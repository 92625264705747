.campaign {
  background-color: #f7f7fa;

  .main-info {
    margin-top: 10px;
  }
  .main-info,
  .description {
    padding: 14px;
  }
}
