body {
  font-size: 2rem;
  // background-color: #f7f7fa;
}

.link {
  display: block;
  text-decoration: none;
  color: black;
}

.form-select {
  .input-label {
    font-size: 1.5rem !important;
  }
}

.numeric {
  margin-left: 1rem;
}

.pointer {
  cursor: pointer;
}
