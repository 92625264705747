@import '../../../../../../config/constants.scss';

.price {
  &__campaignDuration {
    margin-top: 125px;

    .input-number,
    .description {
      margin-top: 13px;
    }

    .description {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 157.2%;

      color: $color-middle-gray;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 10px;
      height: 78px;
    }
  }

  &__campaignDuration.first {
    margin-top: 40px;
  }
}
