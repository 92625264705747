.navbar {
  width: 100%;
  padding: 36px 0 0 32px;
  background: linear-gradient(180deg, #f6455f 0%, #c5478d 100%);
  padding-bottom: 55px;

  .title {
    width: 190px;
    height: 66px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 125.7%;
    color: #ffffff;
  }

  .stages {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 21px;
  }
}
