.rewards {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .selectInput {
      .react-numeric-input input,
      .react-numeric-input {
        width: 100%;
        height: 3.5rem;
      }

      .react-numeric-input input::placeholder {
        font-size: 1.5rem;
      }

      .react-numeric-input input {
        padding-left: 1rem !important;
      }

      .number-input {
        padding-left: 1rem;
        margin-top: 1rem;
        width: 36rem;
      }

      .hide {
        display: none;
      }
    }
  }

  &__addButton {
    width: 70rem;
    height: 10rem;
    margin-top: 2rem;
    border: 0.1rem solid black;
    font-size: 2rem;
    border-radius: 0.5rem;
    outline: none;
    cursor: pointer;
  }
}
