@import '../../../../config/constants.scss';

.campaign {
  .description {
    .description-tip {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      padding: 31px 20px 20px 20px;
      background-color: $color-white;
      box-shadow: -2px 2px 12px rgba(0, 0, 0, 0.13);
      border-radius: 7px;
      cursor: pointer;

      .title,
      .subtitle {
        font-family: 'Poppins';
        font-style: normal;
        color: $color-dark-gray;
        text-align: center;
      }

      .title {
        font-weight: bold;
        font-size: 19px;
        line-height: 119.2%;
      }

      .subtitle {
        margin-top: 5px;
        font-weight: 300;
        font-size: 14px;
      }
    }

    .shipping {
      display: flex;
      margin-top: 20px;

      .text {
        padding-left: 8px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        color: $color-middle-gray;
      }
    }

    .company-description {
      margin-top: 20px;

      .title {
        width: 100%;
        height: 26px;
      }

      .product-description {
        margin-top: 10px;
        padding-right: 10px;
      }
    }

    .serial-number {
      margin-top: 20px;

      & span {
        padding-left: 10px;
      }
    }

    .tags {
      margin-top: 20px;
    }
  }
}
