$border-radius-createProduct-navigation: 1.5rem;

.createProduct__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10rem;
  margin-top: 5rem;

  .back,
  .preview {
    width: 15rem;
    height: 4rem;
    border-radius: $border-radius-createProduct-navigation;
    border: none;
    cursor: pointer;
  }

  .navigation {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    height: 4rem;
    width: 40rem;
    border-radius: $border-radius-createProduct-navigation;
    border: 0.1rem solid gray;
    margin: 0 5rem 0 5rem;

    .nav-elem {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-right: 0.1rem solid gray;

      &:hover {
        background-color: rgb(221, 221, 221);
      }
    }

    .nav-elem:last-child {
      border: none;
      border-top-right-radius: $border-radius-createProduct-navigation;
      border-bottom-right-radius: $border-radius-createProduct-navigation;
    }

    .nav-elem:first-child {
      border-top-left-radius: $border-radius-createProduct-navigation;
      border-bottom-left-radius: $border-radius-createProduct-navigation;
    }

    .active {
      background-color: rgb(221, 221, 221);
    }
  }
}
