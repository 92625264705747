.signup-company {
  position: relative;
  width: 100%;
  max-width: 480px;
  background-color: #f7f7fa;

  &__wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.form_description_container {
  padding-left: 27px;
  padding-right: 27px;
  margin-top: 29px;
  margin-bottom: 50px;
  text-align: center;
}