* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.position-absolute {
  position: absolute;
}
