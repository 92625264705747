@import '../../../../config/constants.scss';

.campaignRegistration__footer {
  height: 81px;
  width: 100%;
  border-top: 1px solid #eeeeee;
  margin-top: 156px;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 27px;
  }
}
