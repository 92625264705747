.form-select {
  .input-label {
    font-size: 1.5rem !important;
  }
  .input-select {
    // height: 4rem;
    font-size: 1.6rem;
  }
}

.react-datepicker-wrapper {
  margin-top: 2rem;
  width: 28rem !important;
  height: 3rem !important;

  .react-datepicker__input-container {
    height: 100%;
  }

  .react-datepicker__input-container input {
    width: 100%;
    height: 100% !important;
    padding-left: 1rem;
  }
}

.react-datepicker-popper {
  .react-datepicker {
    transform: scale(1.5);
    top: 5rem;
    right: -10rem;
  }
}

// #date-label {
// 	font-size: 1.6rem !important;
// }

// #date {
// 	font-size: 1.6rem !important;
// }
