.block {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  width: 70rem;
  // height: 30rem;
  padding: 2rem;
  border-radius: 2rem;
  border: 0.1rem solid black;
  margin-top: 5rem;

  &__input-label {
    font-size: 2rem;
  }

  &__input-info {
    display: flex;
    flex-direction: column;
    padding-left: 3rem;

    .input-description {
      color: red;
      font-size: 1.3rem;
    }

    .duotext,
    .selectInput {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 10rem;
    }

    .input.input {
      outline: none;
      padding: 1rem;
      width: 100%;
    }

    .textarea {
      resize: vertical;
      outline: none;
      width: 100%;
      padding: 1rem;
      min-height: 5rem;
    }

    .button {
      width: 20rem;
      height: 4rem;
      margin-left: 10rem;
    }

    .submit {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .submit-button {
      width: 18rem;
      height: 4rem;
      margin-top: 1rem;
      border-radius: 3rem;
      border: none;
      cursor: pointer;
      background-color: rgb(182, 182, 182);
      outline: none;
    }

    .description {
      text-align: center;
      width: 30rem;
      align-self: center;
      font-size: 1.5rem;
      color: rgb(88, 88, 88);
    }
  }
}
