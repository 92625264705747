@import '../../../../config/constants.scss';

.button {
  width: 100%;
  max-width: 380px;
  height: 50px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  border-radius: 28px;
  outline: none;
  border: none;
  filter: drop-shadow(-2px 2px 12px rgba(0, 0, 0, 0.13));

  letter-spacing: -0.41px;
  color: black;
}

.button-social-network {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 109px;
  height: 50px;
  border: none;
  border-radius: 8px;
}

.button.white {
  background-color: $color-white;
}

.button.orange {
  background: linear-gradient(270deg, #fcba69 0%, #f98140 100%);
}

.button.black {
  background-color: $color-black;
  color: $color-white;
}

.black {
  background: #232326;
}

.blue {
  background: #3b5999;
}

.blue-light {
  background: #55acee;
}
