@import '../../../../../../config/constants.scss';

.price {
  &__inputRadioBlock {
    .inputRadioBlock {
      &__title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 126.2%;

        color: $color-dark-gray;
      }

      &__description {
        margin-top: 10px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 157.2%;

        color: $color-middle-gray;
      }

      &__radioBlock {
        display: flex;
        justify-content: space-between;
        width: 133px;
        margin-top: 10px;
      }
    }
  }

  .inputRadio {
    width: 20px;
    height: 20px;
  }
}
