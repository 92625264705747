@import '../../../config/constants';

.simple__input {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 48px;

  padding: 0 15px;
  border: 1px solid #d5d5d5;
  border-radius: 10px;

  font-weight: normal;
  line-height: 18px;
  letter-spacing: -0.08px;

  .label,
  .input__field {
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    color: $color-dark-gray;
    font-size: 16px;

    border: none;
    background-color: transparent;
    resize: none;
    outline: none;

    &.is__disabled {
      color: $color-light-gray-2;
    }
  }

  .label {
    font-weight: 600;
    font-size: 16px;
    line-height: 126.2%;
  }

  .error__text {
    position: absolute;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 12px;
    color: lightcoral;
    max-width: 200px;
  }
}
