@import '../../../../../../../config/constants.scss';

.custom-radio {
  display: flex;
  position: relative;
  height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .label {
    margin-left: 29px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 125%;

    color: $color-dark-gray;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }

  input:checked ~ .custom-radio::after {
    display: block;
  }

  .custom-radio {
    position: absolute;
    top: 2px;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid $color-light-gray-2;
    border-radius: 50%;
  }

  .custom-radio::after {
    content: '';
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 11px;
    height: 11px;
    background: $color-orange-1;
  }
}
