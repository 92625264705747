@import '../../../../config/constants.scss';

.subscribe-button {
  display: flex;
  flex: 1;
  margin-top: 10px;
  
  & button {
    display: flex;
    flex: 1;
    padding: 10px 20px 10px 20px;
    background: linear-gradient(white, white) padding-box,
    linear-gradient(to left, #EB4DBF, #F98140) border-box;
    border-radius: 4px;
    border: 2px solid transparent;
    cursor: pointer;
    justify-content: center;
  }

  & p {
    margin-left: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 157.2%;
  }
}

.subscribe-button.subscribed {
  & button {
    display: flex;
    flex: 1;
    padding: 10px 20px 10px 20px;
    background-color: #EEE;
    border-radius: 4px;
    cursor: pointer;
    background: none;
    justify-content: center;
  }
}

.campaign {
  .main-info {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      -webkit-line-clamp: 1;

      .company-text {
        display: flex;
        flex-direction: column;
        width: 350px;
        height: 100%;

        .title,
        .subtitle {
          height: 50%;
        }

        .title {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 19px;
          line-height: 124.2%;
          color: $color-black;
        }

        .subtitle {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 22px;
          letter-spacing: -0.41px;
          color: $color-black;
        }
      }

      .company-logo {
        cursor: pointer;
        
        & img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }
    }

    .price {
      display: flex;
      margin-top: 10px;
      height: 38px;

      .company-price {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 100%;
        color: $color-black;
      }

      .company-retail-price,
      .company-retail-price span {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: normal;
        color: $color-black;
      }

      .company-retail-price {
        display: flex;
        align-items: flex-end;
        padding-left: 10px;
        font-size: 16px;

        & span {
          font-size: 20px;
          text-decoration: line-through;
        }
      }
    }

    .progress {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 54px;
      margin-top: 10px;

      .contributors__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;

        .company-contributors {
          display: flex;
          justify-content: space-between;

          .pcs,
          .pcs span {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            color: $color-orange-2;
          }

          .pcs {
            font-size: 26px;

            & span {
              font-size: 17px;
            }
          }

          .avatars {
            display: flex;
            align-items: center;
            position: relative;
            margin-left: 15px;
            cursor: pointer;
            z-index: 1;

            & div {
              width: 25px;
              height: 25px;

              & img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }

          }
        }

        .company-days-left {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 130.2%;
          color: $color-black;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      height: 43px;
      margin-top: 20px;

      .campaign-mainInfo-btn {
        height: 100%;
        border-radius: 4px;
        border: none;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        color: $color-black;
        cursor: pointer;
      }

      .campaign-mainInfo-btn.small {
        width: 116px;
        background: linear-gradient(270deg, #eb4dbf 0%, #f98140 100%);
      }

      .campaign-mainInfo-btn.large {
        // width: 224px;
        flex: 1 1 auto;
        margin-right: 7px;
        background: linear-gradient(270deg, #f98140 0%, #fcba69 100%);
      }
    }

    .company-commission {
      display: flex;
      justify-content: space-between;
      height: 37px;
      width: 100%;
      margin-top: 20px;

      .text,
      .commission {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: normal;
        color: $color-black;
      }

      .text {
        width: 184px;
        font-size: 12px;
      }

      .commission {
        display: flex;
        align-items: center;
        width: 144px;
        font-size: 16px;

        & span {
          padding-left: 10px;
          font-weight: 600;
          font-size: 21px;
          color: $color-orange-1;
        }
      }
    }
  }
}
