@import '../../../../config/constants.scss';

.authentication__title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 30px;
  margin: 0 auto;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 125.7%;

  color: $color-black;

  & span {
    color: $color-orange-1;
  }
}

.login-title {
  width: 280px;
  height: 30px;
  margin-top: 14px;
}

.signup-title {
  width: 338px;
  height: 36px;
  margin-top: 17px;
}

.signup-product-title {
  width: 260px;
  height: 58px;
  margin-top: 17px;
  text-align: center;
}
