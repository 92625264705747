@import '../../../../../config/constants.scss';

.stages {
  &__stage {
    display: grid;
    grid-template-columns: 40px 108px;
    grid-template-rows: 20px;
    height: 20px;
    width: 148px;
    margin-top: 12px;

    &:first-child {
      margin-top: 0;
    }

    .status,
    .title {
      display: flex;
      align-items: center;
      height: 20px;
    }

    .title {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 144.2%;

      color: $color-white;
    }
  }
}

.stages__stage.inactive .title {
  color: rgba(255, 255, 255, 0.33);
}

.stages__stage.active .title,
.stages__stage.accepted .title {
  color: $color-white;
}
