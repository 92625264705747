@import '../../../../config/constants.scss';

.authentication__input {
  display: grid;
  grid-template-columns: 1fr 232px;
  grid-gap: 10px;
  align-items: center;
  height: 48px;
  margin-bottom: 10px;

  .label,
  .inputField {
    font-family: 'Poppins';
    font-style: normal;
    color: $color-dark-gray;
  }

  .label {
    font-weight: 600;
    font-size: 16px;
    line-height: 126.2%;
  }

  .inputField {
    height: 48px;

    padding: 0 15px 0 15px;
    border: 1px solid #d5d5d5;
    border-radius: 7px;

    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.08px;
  }

  .errorText {
    position: absolute;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 12px;
    color: lightcoral;
    max-width: 200px;
  }
}

.authentication__textarea-input {
  display: grid;
  grid-template-columns: 1fr 232px;
  grid-gap: 10px;
  height: 99px;
  margin-bottom: 10px;

  .label,
  .inputField {
    font-family: 'Poppins';
    font-style: normal;
    color: $color-dark-gray;
  }

  .label {
    padding: 15px 0 0 0;

    font-weight: 600;
    font-size: 16px;
    line-height: 126.2%;
  }

  .textarea-inputField {
    resize: none;
    height: 99px;

    padding: 15px;
    border: 1px solid #d5d5d5;
    border-radius: 7px;

    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.08px;

    font-family: 'Poppins';
    font-style: normal;
    color: $color-dark-gray;
  }

  .errorText {
    position: absolute;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 12px;
    color: lightcoral;
    max-width: 200px;
  }
}
