.productImage__imageBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 70px;
  width: 70px;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  margin-top: 8px;
  margin-right: 8px;

  .close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 7px;
    right: 7px;
  }

  & input {
    opacity: 0;
    width: 70px;
    height: 70px;
    position: absolute;
    cursor: pointer;
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;

    .image {
      max-width: 55px;
      max-height: 55px;
    }
  }
}
