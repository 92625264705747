@import '../../../config/constants.scss';

.login {
  position: relative;
  width: 100%;
  max-width: 480px;
  background-color: #f7f7fa;

  &__wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .logo {
    &__wrapper {
      position: relative;
      height: 187px;
      width: 100%;
    }

    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 172px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
}
