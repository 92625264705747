.authentication_footer {
  .subtitle {
    width: 100%;
    height: 18px;
    margin: 0 auto;
    margin-top: 40px;
    text-align: center;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.08px;
  }

  .socials {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 50px;
    margin: 10px auto 74px auto;
  }
}