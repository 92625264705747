@import '../../../../config/constants.scss';

.login-form {
  margin-top: 27px;

  .inputs,
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
  }

  .inputs {
    height: 106px;
    padding: 0 22px 0 15px;
  }

  .buttons {
    height: 110px;
    margin-top: 40px;
    margin-bottom: 40px;
    align-items: center;
    padding: 0 15px 0 15px;
  }
}

.signup-form {
  margin-top: 21px;

  .inputs,
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
  }

  .inputs {
    height: 280px;
    padding: 0 15px;
  }

  .buttons {
    height: 110px;
    margin-top: 40px;
    align-items: center;
    padding: 0 15px 0 15px;
  }

  .auth_footer {
    display: flex;
    margin-top: 40px;
    margin-bottom: 40px;
    justify-content: center;
    align-items: center;

    & a {
      margin-left: 12px;
      color: $color-black;
    }
  }
}

.signup-company-form {
  margin-top: 29px;

  .inputs,
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .inputs {
    padding: 0 15px;

    .label {
      font-size: 14px;
    }
  }

  .buttons {
    height: 50;
    margin-top: 40px;
    align-items: center;
    padding: 0 15px 0 15px;
  }

  .signup-company-footer {
    margin: 40px 0 0 0;
    padding: 0 15px 0 15px;
    width: 100%;
    height: 112px;
    text-align: center;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 157.2%;

    color: $color-middle-gray;
  }
}
