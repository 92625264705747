@import './constants.scss';

.header-3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 126.2%;
  color: $color-dark-gray;
}
