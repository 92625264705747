@import '../../../../config/constants.scss';

.campaignRegistration__price {
  width: 100%;
  margin-top: 50px;

  .container {
    padding: 0 28px;

    .price {
      &__title {
        width: 272px;
        height: 52px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 119.2%;

        color: $color-black;
      }

      &__pricesCounter {
        margin-top: 40px;
      }
    }
  }
}
