@import '../../../../../../config/constants.scss';

.price__inputTitle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 248px;
  height: 90px;
  margin-top: 40px;

  .inputTitle__title {
    height: 28px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 126.2%;

    color: $color-dark-gray;
  }
}
