@import '../../../../../../config/constants.scss';

.productDetails__location {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  .title {
    height: 24px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 126.2%;

    color: $color-dark-gray;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 78px;
    margin-top: 10px;
  }

  .description {
    margin-top: 10px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 157.2%;

    color: $color-middle-gray;
  }
}

.MuiPopover-paper {
  width: 380px !important;
  margin-top: 34px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}
