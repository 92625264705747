@import '../../../../config/constants.scss';

.campaignRegistrations {
  &__productDetails {
    width: 100%;
    margin-top: 50px;

    .container {
      padding: 0 28px;
    }

    .title {
      width: 262px;
      height: 54px;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: bold;
      font-size: 19px;
      line-height: 119.2%;

      color: $color-black;
    }

    .campaign-title {
      margin-top: 50px;
    }

    .short-description {
      margin-top: 30px;

      .description {
        margin-top: 10px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 157.2%;

        color: $color-middle-gray;
      }
    }

    .product-images {
      margin-top: 30px;
    }
  }
}
