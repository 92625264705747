@import './constants.scss';

.text-1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 157.2%;
  color: $color-dark-gray;
}

.text-3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 157.2%;
  color: $color-dark-gray;
}
