@import '../../../../config/constants.scss';

.campaign {
  .overview {
    background-color: $color-white;

    .social-counter {
      &__wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        height: 60px;
        padding-right: 15px;
      }

      display: flex;
      justify-content: space-between;
      width: 150px;
    }

    .slider {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 370px;
      margin-top: 52px;

      .image {
        width: 100%;
        padding: 0 20px;

        & img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.overview-social-counter-fontConfig {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.38px;
  color: $color-middle-gray;
}

.slider .swiper-container {
  height: 100% !important;
}

.slider .swiper-container .swiper-pagination {
  bottom: 17px !important;
}

.overview-swiper-slide {
  display: flex;
  justify-content: center;

  & img {
    height: 274px !important;
    max-width: 100%;
    object-fit: contain;
  }
}
