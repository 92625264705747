@import '../../../config/constants';

.list__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;

  padding: 0 15px;
  border: 1px solid #d5d5d5;
  border-radius: 10px;

  font-weight: lighter;
  line-height: 18px;
  letter-spacing: -0.08px;
}
