@import '../../../../config/constants.scss';

.campaignRegistration__button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 100%;
  border: none;

  .container {
    display: flex;
    justify-content: center;
    // width: 148px;

    .text {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.41px;

      color: $color-dark-gray;
    }

    .text.not-active {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.41px;

      color: $color-light-gray-2;
    }
  }

  .container.arrowDown {
    .text {
      margin-right: 4px;
    }

    .arrowIcon {
      margin-left: 4px;
    }
  }

  .container.arrowRight {
    .text {
      margin-right: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .arrowIcon {
      margin-left: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  background-color: #ffffff;
  box-shadow: -2px 2px 12px rgba(0, 0, 0, 0.13);
  border-radius: 41px;

  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.41px;
}
