.campaignRegistration {
  width: 100%;
  max-width: 480px;
  position: relative;

  &__wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
