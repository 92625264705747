@import '../../../config/constants';

.categoryTag {
  display: inline-flex;
  align-items: center;
  position: relative;
  height: 28px;
  padding: 0 16px 0 16px;
  border: 1px solid $color-light-gray-2;
  border-radius: 42px;
  margin-top: 10px;
  margin-right: 10px;

  &:first-child {
    margin-top: 0;
  }

  .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 28px;
    letter-spacing: -0.24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    display: flex;
    justify-content: center;
    align-items: center;

    color: $color-middle-gray;
  }
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 9px;
  transform: translateY(-50%);
}

.cross {
  padding: 0 36px 0 16px;
}
